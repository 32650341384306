.admin__list__card {
  border-radius: 40px;
  background: var(--keks-grey-light);
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.admin__btn {
  border-radius: 40px;
  border: 0;
  margin: 0.1rem;
  padding: 0.5rem;
  cursor: pointer;
  color: #fff;
}

.btn__nav {
  background: var(--keks-blue-signal);
}

.btn__status {
  background: var(--keks-red-beet-root);
}
.btn__del {
  background: var(--keks-red-tomato);
}

.list {
  list-style: none;
  padding-left: 0;
}

.admin__sum {
  display: block;
}
