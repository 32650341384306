.status__overall__content {
  background: var(--keks-blue-shade);
  padding-top: 4rem;
}
.status__overall__content__green {
  background: var(--keks-green-shade);
  padding-top: 4rem;
}
.status__overall__content__yellow {
  background: var(--keks-yellow-banana);
  padding-top: 4rem;
}
.status__overall__content__blue {
  background: var(--keks-blue-signal);
  padding-top: 4rem;
}

.status__container {
  max-width: 568px;
  margin: auto;
}

.status__content {
  /*  padding-top: 4rem; */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

.status__red {
  background: var(--keks-onion);
}

.status__heading__area {
  margin-bottom: 0;
}

.status__heading {
  padding-left: 1rem;
  text-transform: uppercase;
  color: var(--keks-red-tomato);
}

.status__message {
  background: var(--keks-red-beet-root);
}
.status__message__green {
  background: var(--keks-green-avocado);
}
.status__message__blue {
  background: var(--keks-blue-reflex);
}
.status__message__redshade {
  background: var(--keks-red-shade);
}

.status__message__text {
  padding: 2rem;
  color: #fff;
}

.status__basket__list {
  background: var(--keks-orange-tomato);
  border-radius: 8px;
  margin-top: 1rem;
}

.status__basket__list__details {
  margin: 1rem;
  padding: 1rem;
}

.status__basket__value {
  float: right;
}

.status__direction {
  text-decoration: underline;
}

.status__no__margin {
  margin: 0;
}

.status__pickup__info {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.status__picker__details {
  background: var(--keks-red-beet-root);
  border-radius: 8px;
}

.status__picker__text {
  color: #fff;
  padding: 1rem;
}
