/* :root {
  --keks-blue-shade: #d6d6f4;
    --keks-grey:#a4bcbd;
  --keks-blue:#2032d4;

} */



.shoppingCart__content {
  background: var(--keks-blue);
  padding-bottom: 17rem;
}

.heading__shoppingcart {
  color: white;
  margin: 0;
  padding-left: 1rem;
  padding-top: 4rem;
}

.delivery__textArea {
  padding: 1rem;
  color: #fff;
  text-align: center;
}

.change__btn {
  margin-top: 1rem;
  text-decoration: underline;
  background: var(--keks--onion);
  border: 0;
  color: var(--keks-red-shade);
  cursor: pointer;
}

.card__shoppingcart {
  background: var(--keks-grey);
/*   border-radius: 40px; */
  /* padding-bottom: 1rem; */
}

@media screen and (min-width: 1024px) {
  .shoppingcart__content__area {
    padding-top: 10rem;
  }
}

.shoopingcart__products {
  padding: 1rem;
}

.shoppingcart_img {
  position: static;
  width: 72px;
  height: 72px;
  left: 16px;
  top: 16px;
  border-radius: 50%;
  object-fit: cover;
}

.food__img__shoppingcart {
  padding: 1rem;
  padding-bottom: 0;
}

.modal_shoppingcart {
  border-radius: 40px;
  background: var(--keks-onion);
  margin: 1rem;
  padding: 1rem;
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .modal_shoppingcart {
    margin-top: 11rem;
  }
}

.modal_shoppingcart__lieferung {
  border-radius: 40px;
  background: var(--keks-yellow-banana);
  margin: 1rem;
  padding: 1rem;
  margin-bottom: 0;
}

.shoopingcart__heading__price {
  display: flex;
  justify-content: space-between;
}

.card__body__shopping_cart {
  padding: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0;
}

.btn-group {
  display: flex;
}

.shoppingcart_btn_group--edit {
  background: var(--keks-white-mozzarella);
  border: 0;
  color: var(--keks--onion);
  cursor: pointer;
}

.shoppingcart__btns--plus__minus {
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  background: #fff;
  flex-grow: 1;
}

.shoppingcart_btn_group {
  flex-grow: 1;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
}

.shoppingcart__btn {
  width: 32px;
  height: 32px;
  color: #fff;
  /* r+b/red tomato */
  border: 0;
  border-radius: 18px;
  font-size: x-large;
  cursor: pointer;

  /* Inside Auto Layout */
}

.btn_minus {
  background: var(--keks-red-tomato);
}

.btn_plus {
  background: var(--keks-grey-coal);
}

.shoppingcart__item__del {
  text-decoration: underline;
  background: var(--keks-grey);
  border: 0;
  cursor: pointer;
  color: #ff0000;
  padding-bottom: 1rem;
}
