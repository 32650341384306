:root {
  --color-primary: #00aaff;
  --keks-red-beet-root: #d54f6f;
  --keks-onion: #8f0067;
  --keks-red-tomato: #c12701;
  --keks-blue: #001489;
  --keks-blue-reflex: #001489;
  --keks-blue-shade: #d6d6f4;
  --keks-green-shade: #d0eeaa;
  --keks-red-shade: #f5aedd;
  --keks-yellow-corn: #fdb515;
  --keks-yellow-banana: #f5c8a8;
  --keks-green-rucola: #425500;
  --keks-green-avocado: #608d27;
  --keks-white-mozzarella: #f3f0e2;
  --keks-grey-coal: #1d1d22;
  --keks-grey-light: #d4ccc8;
  --keks-orange-tomato: #eb7a2a;
  --keks-grey:#a4bcbd;
  --keks-blue:#2032d4;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  font-family: Space Grotesk;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
}

.container {
  max-width: 1340px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

/* Info Section */

.home__content__area {
  padding-bottom: 11rem;
}

@media screen and (min-width: 1024px) {
  .home__content__area {
    padding-bottom: 0;
  }
}

.info {
  padding: 1rem;
  text-align: start;
  background: var(--color-primary);
}

.info--text {
  color: #fff;
}

.info--btn {
  color: #fff;
  text-align: end;
}

.hello__area {
  max-width: 540px;
  margin: auto;
}

.hello__buble {
  /* Auto Layout */
  margin-bottom: 1rem;
  padding: 16px 24px;
  background: var(--keks-green-shade);
  border-radius: 40px;
  text-align: left;
}

/* Header Block */
.block {
  /* background: var(--keks-red-beet-root); */
  padding: 1rem;
  text-align: center;
}

.block__div__img {
  margin-top: 1.5rem;
/*   background: #ffffff; */
  border-radius: 400px;
  display: inline-block;
  padding: 50px;
}

.block__img {
  border-radius: 400px;
}

.block--heading {
  margin-top: 0;
}

/* HeaderBlock DS */

.block__ds {
  background: var(--keks-white-mozzarella);
  padding: 1rem;
  text-align: center;
}

.ds__text {
  text-align: start;
}

/* Button */

.btn--header {
  text-align: end;
  margin-bottom: 3px;
}

.btn {
  border-radius: 50px;
  /*  border: 2px solid var(--color-primary); */
  cursor: pointer;
  /*   font-size: 1.2rem;
  font-weight: normal; */
  margin: 1rem 0;
  outline: 0;
  padding: 2px 16px;
  text-align: center;
  white-space: nowrap;
}

.category__btn__content {
  align-items: center;
}

.category__btnArea {
  display: flex;
  justify-content: space-evenly;
}

.btn__category {
  padding: 0;
  border-radius: 56px;
  border: 0;
  /*   padding-left: 1.5rem;
  padding-right: 1.5rem; */
  padding-bottom: 1rem;
  text-align: center;
}

.fixed__cats {
  position: fixed;
  top: 0;

  /* r+b/yellow corn */

  background: var(--keks-yellow-corn);
  border-radius: 0px 0px 32px 32px;
  right: 0;
  left: 0;
  color: #000;

  /* Inside Auto Layout */
}

.contact__btn__fixed {
  position: fixed;
  bottom: 2%;
  right: 15%;
}

.btn__close {
  background: var(--keks-green-rucola);
  color: var(--keks-white-mozzarella);
  border-radius: 24px;
  position: fixed;
}

.blue__bg {
  background: var(--keks-blue-reflex);
}

@media screen and (min-width: 768px) {
  .fixed__cats {
    width: 375px;
    height: 68px;
    left: calc(50% - 375px / 2 + 0.5px);
    padding-bottom: 1rem;
  }
}
/* ToDo Check if this is correct */
@media screen and (min-width: 1024px) {
  .fixed__cats {
    left: calc(25% - 375px / 2 + 0.5px);
  }
}

.btn__green {
  margin: 0;
  border-radius: 24px;
  background: var(--keks-green-rucola);
  border: 0;
  color: #fff;
  padding: 1rem;
}

/* .btn__category__img {
  display: block;
} */

.btn__category__text {
  color: #fff;
  text-align: center;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--blue {
  background: var(--keks-red-beet-root);
  color: #001489;
  border: 3px solid #001489;
  padding: 0.75rem;
  margin: 4px 0px;
}

.btn--white {
  background: var(--keks-red-beet-root);
  color: #fff;
  border: 3px solid #f3f0e2;
  padding: 0.75rem;
  margin: 4px 0px;
}

.btn--onion {
  display: block;
  background: var(--keks-onion);
  padding: 12px;
  color: #fff;
  width: 100%;
  border: 0;
}

.btn--beet-root {
  display: block;
  background: var(--keks-grey);
  padding: 12px;
  color: #fff;
  /* removed buttn inside card beause whole card is clickable */
  /*   width: 100%; */
  border: 0;
}

.btn--bluereflex {
  display: block;
  background: var(--keks-blue-reflex);
  padding: 12px;
  color: #fff;
  width: 100%;
  border: 0;
}

.btn--green-avocado {
  display: block;
  background: var(--keks-green-avocado);
  padding: 12px;
  color: #fff;
  width: 100%;
  border: 0;
}

.btn--yellow {
  display: block;
  background: white;
  padding: 12px;
  color: var(--keks-grey-coal);
  width: 100%;
  border: 0;
}

.btn--onion-white {
  display: block;
  padding: 3px;
  color: var(--keks-onion);
  border: 2px solid var(--keks-onion);
}

.btn--tomato {
  background: var(--keks-red-tomato);
  display: block;
  padding: 12px;
  color: #fff;
  margin: 0;
}

.btn--bluefilled {
  display: block;
  color: #fff;
  padding: 12px;
  background: var(--keks-blue-reflex);
}

.btn--transparent {
  /* Auto Layout */
  color: #fff;
  background: var(--keks-green-avocado);
  border: 2px solid #fff;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 8px;
  padding: 1rem;
}

.btn__beverage_bottom {
  padding: 12px;
}

.btn--avocado {
  display: block;
  background: var(--keks-green-avocado);
  padding: 12px;
  color: #fff;
  margin: 1rem;
}

.btn--primary:hover {
  background: #3a8ffd;
}

.btn--secondary {
  background: var(--color-secondary);
  color: var(--color-primary);
}

.btn--secondary:hover {
  background: #00c8eb;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #ec3000;
}

.btn--streched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

.btn--outline {
  background: #fff;
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
}

.btn--outline:hover {
  background: var(--color-headings);
  color: #fff;
}

.btn--method {
  margin: 0.5rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn--category {
  font-size: 1rem;
  text-align: center;
  display: block;
  background: #fff;
  margin: 0.5rem;
  padding: 0.75rem;
}

.method--btnarea {
  padding: 1rem;
  max-width: 536px;
  margin: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.orderArea {
  background: var(--keks-grey);
  padding: 1rem;
}

.methodArea {
  background: var(--keks-blue-reflex);
  padding: 1rem;
}

.foodArea {
  background: var(--keks-blue);
  padding: 1rem;
}

.pastaArea {
  background: var(--keks-red-shade);
  padding: 1rem;
}

.salatArea {
  background: var(--keks-green-shade);
  padding: 1rem;
}

.bevArea {
  background: var(--keks-yellow-banana);
  padding: 1rem;
}
.aboutArea {
  background: var(--keks-red-beet-root);
  padding: 1rem;
}
.restaurantArea {
  background: var(--keks-white-mozzarella);
}
.infoArea {
  background: var(--keks-grey-coal);
}

/* Grid */

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid--1x {
  display: grid;
  grid-template-columns: 1fr;
}

.grid--2x1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.grid--1x2 {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

@media screen and (min-width: 768px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .grid--1x {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .grid--1x {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid__details {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .grid__details {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Method */

.method {
  text-align: center;
  padding: 1rem;
  background: #fff;
  border-radius: 40px;
  border: 2px solid #fff;
}

.order--text {
  color: var(--keks-yellow-corn);
  text-align: center;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px 32px;
}

.category__food {
  display: flex;
  flex-direction: column;
}

.category__bev {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .category__food {
    display: block;
    padding: 1rem;
  }

  .category__bev {
    display: block;
    padding: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .category__food {
    display: block;
    padding: 1rem;
  }

  .category__bev {
    display: block;
    padding: 1rem;
  }
}

/* .card__btn {
  border: 0;
  text-align: left;
  background: #fff;
  cursor: pointer;
  width: 100%;
} */

.card {
  display: block;
}

.card__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__body__footing {
  padding-bottom: 1rem;
}

.img__circle {
  margin-top: 1rem;
  width: 311px;
  height: 311px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
}

@media screen and (min-width: 100px) {
  .img__circle {
    padding-left: 0;
    width: 311px;
    height: 311px;
  }
}

@media screen and (min-width: 568px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .card {
    display: flex;
  }

  .card__body {
    display: flex;
  }

  .img__circle {
    padding-left: 1rem;
    width: 72px;
    height: 72px;
  }
}

@media screen and (min-width: 768px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .card {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    display: block;
  }

  .card__body {
    display: block;
  }

  .img__circle {
    padding-left: 0;
    width: 208px;
    height: 208px;
  }
}

@media screen and (min-width: 1024px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .card {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    display: block;
  }

  .card__body {
    display: block;
  }

  .img__circle {
    padding-left: 0;
    width: 208px;
    height: 208px;
  }
}

.food {
  background: #fff;
 /*  border-radius: 32px; */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 4px;
  overflow: hidden;
}

.card__body__heading > h4,
h3 {
  margin: 0;
}
.card__body__heading > p {
  margin-top: 0;
}

.card__body {
  padding: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0;
}

.btn__group {
  /* r+b/green shade */
  background: var(--keks-green-shade);
  border-radius: 24px;
  display: flex;

  /* Inside Auto Layout */

  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.btn--red-shade {
  flex-grow: 1;
  color: var(--keks-onion);
  margin: 8px;
}

.btn--red-shade > a:focus {
  /* ima: url(./images/hacken.png); */
  background: #f5aedd;
  /* r+b/blue reflex */

  border: 3px solid #001489;
  border-radius: 24px;
  padding: 12px;
}

.btn--food {
  border: 0;
  color: #fff;
  margin: 0px 8px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
a {
  color: inherit;
}
/* .btn__group > a:active {
  background: #fff;
}

.btn__group > a:focus {
  background: #fff;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
} */

.food__heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 33px;
}

.food__heading--keks {
  color: var(--keks-grey);
}

/* .food__heading--pasta {
  color: var(--keks-blue-reflex);
}

.food__heading--drinks {
  color: var(--keks-green-rucola);
}

.food__heading--salat {
  color: var(--keks-onion);
}
 */
.food__btn--add {
  padding: 0.5rem;
}

.beverage {
  margin: 0.5rem;
  border-radius: 40px;
  background: #fff;
}

.beverage__grid {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 500px) {
  /* this comes to when a screen is wider than 768p standard breakpoint for tablets */
  .beverage__grid {
    /* we take all the avaiable space devide it by two and than give each column on slice */
    grid-template-columns: repeat(2, 1fr);
  }
}

.beverage > h3 {
  text-align: center;
}

.beverage__add__btn {
  padding-bottom: 1rem;
}

.bev__body {
  display: block;
  flex-grow: 1;
  align-self: stretch;
}

.form {
  margin: 1rem;
  display: block;
}

.about {
  background: var(--keks-blue-shade);
  border-radius: 40px;
}

.about__img {
  object-fit: cover;
  margin: auto;
  width: 100%;
  height: 200px;
  border-radius: 40px 40px 0 0;
}

.about__heading {
  /* Head / H2 */
  font-style: normal;
  font-weight: 900;
  font-size: 58px;
  line-height: 54px;
  /* or 93% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* r+b/blue reflex */

  color: var(--keks-blue-reflex);

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}

.about__card__body {
  margin: 1rem;
}

.about__text {
  padding: 1rem;
}

.about__social {
  margin-top: 1rem;
  border-radius: 40px;
  background: var(--keks-red-shade);
  padding: 2rem;
}

@media screen and (min-width: 768px) {
  .about__social {
    display: flex;
    align-items: center;
  }
  .about__social__btnArea {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.about__last__text {
  font-weight: bold;
}

.about__social__heading {
  text-transform: uppercase;
  font-size: 58px;
  line-height: 54px;
  margin-top: 1rem;
  color: var(--keks-red-tomato);
}

.btn--social {
  display: flex;
  justify-content: center;
  border: 2px solid var(--keks-blue-reflex);
  color: var(--keks-blue-reflex);
}

.info__content {
  color: var(--keks-white-mozzarella);
  margin: 0;
  padding: 1rem;
}

.info__content__kontakt__text > h6 {
  margin-bottom: 0;
  font-size: 14px;
}

.info__opening__area {
  display: flex;
}

.info__opening_hours {
  padding-left: 1rem;
}

.info__opening_days > p {
  margin: 0;
}

.info__opening_hours > p {
  letter-spacing: 0.015em;
  margin: 0;
}

.info__about__content > p {
  margin: 0;
}

.info__about__social > a {
  display: block;
  color: var(--keks-white-mozzarella);
}

.info__bottom__nav > p {
  margin-bottom: 0;
}

.info__bottom__nav > a {
  display: flex;
  color: var(--keks-white-mozzarella);
}

.btn--corn {
  display: block;
  background: var(--keks-yellow-corn);
  color: var(--keks-onion);
}

.about__tel-text {
  /* Button / Button Primary */

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.005em;
  font-feature-settings: "pnum" on, "onum" on, "ss03" on, "ss02" on, "ss04" on,
    "zero" on;
}

@media screen and (min-width: 568px) {
  /*   .info__telefon_btn {
    display: flex;
    justify-content: center;
  } */
  .info__content__kontakt {
    display: flex;
  }
  .info__content__kontakt__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
}

.restaurant__heading {
  color: var(--keks-green-rucola);
  text-transform: uppercase;
  font-weight: 900;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  margin-top: 1rem;
}

.restaurant__add > h5 {
  font-size: 18px;
  line-height: 22px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.restaurant__add > p {
  margin: 0;
}

.restaurant__pic__text {
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .restaurant__pic__area {
    display: flex;
    justify-content: space-evenly;
  }
}

.order__bar {
  /*   display: flex; */
  display: none;
  background: var(--keks-green-avocado);
  border-radius: 32px 32px 0px 0px;
  overflow: hidden;
  position: sticky;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

/* @media screen and (min-width: 768px) {
  .order__bar {
    width: 375px;
    top: 0;
    bottom: initial;
    border-radius: 0px 0px 32px 32px;
  }
} */

@media screen and (min-width: 768px) {
  .order__bar {
    width: 390px;
  }
}

@media screen and (min-width: 1024px) {
  .order__bar {
    top: 0;
    right: 15%;
    bottom: initial;
    border-radius: 0px 0px 32px 32px;
  }
}

.order__heading {
  padding: 2rem;
  color: var(--keks-red-shade);
  font-weight: 900;
  font-size: 44px;
  line-height: 48px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.06em;
}

.orderBar__btns {
  display: flex;
  padding-bottom: 1rem;
}

.orderBar__btn {
  /* Auto Layout */

  display: flex;
  align-self: stretch;
  flex-grow: 1;
}

.yellow__badge {
  position: absolute;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 18px;
  text-align: center;
  margin-left: 5rem;
  color: var(--keks-grey-coal);
}

.create_product_modal{  
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /* overflow: auto; */ /* Enable scroll if needed */
  /*   background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.4);  */
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  margin-top: 0;
}

.modal-content {
  background-color: var(--keks-grey);
  /* position: absolute; */
  margin: auto;
  margin-top: 80px;
  padding-bottom: 20px;
  border: 1px solid #888;
  width: 90%;
}

.modal-closeBtn {
  float: right;
}

.logout {
  background-color: red;
}

.createProductBtn {
  background-color: yellow;
  color: black;
}