.checkout__content {
  background: var(--keks-grey);
  margin-top: 0;
  padding-bottom: 6rem;
}

@media screen and (min-width: 1024px) {
  .checkout__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 768px) {
  .checkout__content {
    padding: 7.5rem;
  }
}

.chackout__cancel__btn {
  background: var(--keks-red-beet-root);
  color: var(--keks-green-shade);
  border: 0;
  border-radius: 24px;
  padding: 0.5rem;
  margin: 1rem;
  position: fixed;
  cursor: pointer;
  left: 0;
}

.checkout__heading {
  margin: 1rem;
  padding-top: 4rem;
  margin-top: 0;
}

.checkout__heading > h3 {
  color: var(--keks-green-rucola);
  text-transform: uppercase;
}

.checkout__info {
  background: var(--keks-white-mozzarella);
  text-align: center;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.25rem;
}

.checkout__payment__details {
  background: white;
  border-radius: 0px 0px 8px 8px;
  padding: 1rem;
}

.checkout__total__price {
  float: right;
}

.checkout__picker__details {
  margin: 1rem;
}

.checkout__input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 24px;
  border: 0;
  outline: 0;
}

.checkout__warning {
  background: #ff0000;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  margin: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.checkout__warning__text {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* @media screen and (min-width: 768px) {
  .checkout__warning {
  }
} */

@media screen and (min-width: 768px) {
  .checkout__warning {
    width: 327px;
    height: 46px;
    top: 0;
    /* left: 65%; */
    margin: auto;
    bottom: initial;
  }
  .checkout__warning__text {
    padding: 0;
  }
}

.checkout__form__completed {
  background: var(--keks-red-beet-root);
  border-radius: 32px 32px 0px 0px;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .checkout__form__completed {
    width: 327px;
    height: 46px;
  }
}

@media screen and (min-width: 1024px) {
  .checkout__form__completed {
    top: 0;
    left: 65%;
    border-radius: 0px 0px 40px 40px;
  }
}

.checkout__gotopay__btn {
  background: #3351ff;
  width: 100%;
  color: #fff;
  padding: 1rem;

  border: 0;
  border-radius: 24px;
  cursor: pointer;
}

.checkout__payment__checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--keks-blue);
}

.checkout__payment__select__details {
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.checkout__payment__select__details input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkout__payment__select__details
  input:checked
  ~ .checkout__payment__checkmark {
  background: var(--keks-blue);
}

.checkout__payment__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkout__payment__select__details
  input:checked
  ~ .checkout__payment__checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkout__payment__select__details .checkout__payment__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkout__payment__checkmark__label {
  margin-left: 3rem;
}

.inputContainer img {
  position: absolute;
  padding-top: 0.75rem;
}

.checkout__input__hacken {
  float: right;
  padding-right: 2rem;
}

.checkout__ds_ag {
  background: var(--keks-yellow-corn);
  border-radius: 40px;
  margin: 1rem;
}

.checkout__ds_ag_text {
  margin: 1rem;
  padding: 1rem;
}

.checkout__ag {
  text-decoration: underline;
  cursor: pointer;
}
