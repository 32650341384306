:root {
  --keks-grey-coal: #1d1d22;
  --keks-orange-tomato: #eb7a2a;
  --keks-green-avocado: #608d27;
  --keks-grey:#a4bcbd;
  --keks-blue:#2032d4;
}

.shopping__bottom__bar {
  /*   display: flex; */
  background: var(--keks-grey);
  border-radius: 0px 0px 0px 0px;
  /*   overflow: hidden; */

  position: fixed;
  bottom: 0;
  /* left: 0px; */
  right: 0;
  width: 100%;
  color: white;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .shopping__bottom__bar {
    width: 390px;
  }
}

@media screen and (min-width: 1024px) {
  .shopping__bottom__bar {
    top: 0;
    /* right: 15%; */
    right: 0;
    bottom: initial;
    border-radius: 0px 0px 0px 0px;
  }
}

/* @media screen and (min-width: 500px) {
  .shopping__bottom__bar {
    left: 10%;
    width: 375px;
  }
} */

/* @media screen and (min-width: 1024px) {
  .shopping__bottom__bar {
    top: 0;
    left: 65%;
    bottom: initial;
    width: 375px;
    border-radius: 0px 0px 32px 32px;
  }
} */

.bottom__bag {
  margin: 1rem;
  margin-top: 0;
  text-align: left;
}

.bottom__text__shoppingcart {
  /* margin: 1rem; */
  margin-right: 0.5rem;
  margin-top: 1.5rem;
  color: #ffffff;
  text-align: start;
  padding-top: 1rem;
}

.btn__arrow__right {
  padding-left: 8px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

/* .order__btn__area {
  margin: 1rem;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
} */

.bottom__text__right {
  float: right;
}
